(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.ajuntaments.controller:AjuntamentsCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments')
  .controller('AjuntamentsCtrl', AjuntamentsCtrl);

  function AjuntamentsCtrl(AclService, ajuntaments,ajuntamentsread) {
    var vm = this;
    vm.isAdmin = AclService.isAdmin();
    
    vm.visibletab=vm.isAdmin;
    vm.ctrlName = 'AjuntamentsCtrl';
    vm.llistaAjuntaments = ajuntaments;
    vm.llistaAjuntamentsread = ajuntamentsread;
    vm.query = {
      order: 'name'
    };
    vm.selected = [];
    vm.select = undefined;

    vm.can = AclService.can;
  }
}());
